import { Injectable } from '@angular/core';

@Injectable()
export class FirebrakeService {
    
    contender = {
        firstname: "",
        lastname: "",
        email: "",
        gender: "",
        team: "",
        start: "",
        finish: ""
    }

    constructor() { }
    
    addContender(contender) {
        console.log("Add a contender");
    }

    getContender(contender) {
        console.log("Get contender details");
    }
}